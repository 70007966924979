<template>
  <div
    ref="cursorRef"
    class="pointer-events-none fixed left-0 top-0 z-[100] block h-5 w-5 origin-center transform-gpu"
    :class="[isVisible ? 'opacity-100' : 'opacity-0']"
  >
    <div
      class="transform-gpu transition-transform duration-300 ease-out"
      :class="[
        hasMoved ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
        {
          'rotate-[135deg] scale-[3]': cursorPlay
        }
      ]"
    >
      <svg width="27" height="28" viewBox="0 0 27 28" fill="none">
        <g filter="url(#filter0_b_2252_5174)">
          <path
            d="M0.0796437 0.579655L26.6704 7.67051L7.17051 27.1704L0.0796437 0.579655Z"
            fill="#12E2FF"
            fill-opacity="0.5"
          />
          <path
            d="M7.43017 26.2036L0.785289 1.2853L25.7036 7.93018L7.43017 26.2036Z"
            stroke="#12E2FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_2252_5174"
            x="-19.9203"
            y="-19.4204"
            width="66.5907"
            height="66.5908"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_2252_5174"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_2252_5174"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { getMousePos } from '~/utils'

const store = useAppStore()
const isVisible = ref(true)
const hasMoved = ref(false)
const cursorPlay = ref(false)
const cursorRef = ref<HTMLElement | null>(null)

onMounted(() => {
  if (cursorRef.value) {
    gsap.set(cursorRef.value, { xPercent: -50, yPercent: -50 })

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    let mouse = { x: 0, y: 0 }

    const speed = 1
    const setX = gsap.quickSetter(cursorRef.value, 'x', 'px')
    const setY = gsap.quickSetter(cursorRef.value, 'y', 'px')

    window.addEventListener('mousemove', (e) => {
      if (!hasMoved.value) {
        hasMoved.value = true
      }
      mouse = getMousePos(e)
    })

    gsap.ticker.add(() => {
      // adjust speed for higher refresh monitors
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())

      pos.x += (mouse.x - pos.x) * dt
      pos.y += (mouse.y - pos.y) * dt

      setX(mouse.x)
      setY(mouse.y)
    })
  }

  // Events
  useListen('cursor:visibility', (bool) => {
    if (bool) {
      isVisible.value = true
    } else {
      isVisible.value = false
    }
  })

  useListen('cursor:scale', (bool) => {
    if (bool) {
      cursorPlay.value = true
    } else {
      cursorPlay.value = false
    }
  })
})

onUnmounted(() => {
  useUnlisten('cursor:visibility')
  useUnlisten('cursor:scale')
})

useHead({
  bodyAttrs: {
    class: computed(() => (store.isCustomCursor ? 'cursor-hide' : ''))
  }
})
</script>
