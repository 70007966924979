<template>
  <Stars
    v-if="isDesktop"
    :radius="50"
    :depth="50"
    :count="1500"
    :size="0.1"
    :size-attenuation="true"
  />
</template>

<script setup lang="ts">
const { isDesktop } = useDevice()
</script>
