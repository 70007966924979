<template>
  <Precipitation
    :rotation="[-2.5, 1, 0]"
    :position="[0, -2, 5.5]"
    :randomness="2"
    :speed="0.000001"
    :count="25"
    :area="[10, 10, 1]"
    :alphaMap="texture"
    :color="0x12e2ff"
    :opacity="0.6"
  />
  <Precipitation
    :rotation="[-2.5, 1, 0]"
    :position="[0, 3, 7.5]"
    :randomness="2"
    :speed="0.000001"
    :count="25"
    :area="[10, 10, 1]"
    :alphaMap="texture"
    :color="0xdc3d43"
    :opacity="0.6"
  />
  <Precipitation
    :rotation="[-2.5, 1, 0]"
    :position="[-12, -4, 10]"
    :randomness="2"
    :speed="0.000001"
    :count="20"
    :area="[10, 20, 1]"
    :alphaMap="texture"
    :color="0xffffff"
    :opacity="0.6"
  />
</template>

<script setup lang="ts">
import { TextureLoader } from 'three'
import exampleTexture from '~/assets/images/icons/star-alpha.png'

const textureLoader = new TextureLoader().load(exampleTexture)

const texture = ref()
texture.value = textureLoader
</script>
