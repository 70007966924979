export const useModals = () =>
  useState('modals', () => ({
    video: false
  }))

export default function () {
  const modals = useModals()

  return {
    isVideoVisible: computed(() => modals.value.video),
    showVideo: () => (modals.value.video = true),
    hideVideo: () => (modals.value.video = false)
  }
}
