<script setup lang="ts">
import { EffectComposer, Bloom } from '@tresjs/post-processing'
import { BlendFunction } from 'postprocessing'

const { isDesktop } = useDevice()
</script>

<template>
  <EffectComposer>
    <!-- <Noise premultiply /> -->
    <Bloom
      :intensity="6"
      :luminance-threshold="0"
      :luminance-smoothing="12"
      :mipmap-blur="true"
      :blend-function="BlendFunction.ADD"
    />
    <!--<Vignette :darkness="0.9" :offset="0.2" />-->
    <SMAA v-if="isDesktop" />
    <!-- <N8AO :scene="scene" :camera="camera" :width="width" :height="height" /> -->
  </EffectComposer>
</template>
