import { useAppStore } from '~/stores/app'

export default function useAlignScrollableElements() {
  const store = useAppStore()
  const route = useRoute()

  if (route.path === '/') {
    const { width } = useWindowSize()
    const offset = width.value <= 768 ? 40 : 100

    const position = store.lenis?.actualScroll || 0
    const heroScroll = store.heroSection?.offsetTop - position || 0
    store.eyeScroll = -heroScroll / offset

    const packagesScroll = store.packagesSection?.offsetTop - position || 0
    store.planetsScroll = -packagesScroll / offset

    const contactScroll = store.contactSection?.offsetTop - position || 0
    store.footerScroll = -contactScroll / offset
  }
}
