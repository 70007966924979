<template>
  <div>
    <Transition
      name="fade-overlay"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="transition-opacity duration-500"
      leave-active-class="transition-opacity duration-500"
    >
      <AppLoading v-if="!hasFinishLoading" :progress="progress" />
    </Transition>
    <TresCanvas ref="canvasRef" v-bind="gl" :window-size="false" :width="w" :height="h">
      <CanvasRenderer />
      <CanvasCamera />
      <CanvasBackdrop v-if="isDesktop" />

      <TresGroup name="HomeScene">
        <Suspense> <CanvasSpace /> </Suspense>>
        <TresGroup ref="eyeRef" name="EyeSection" :position="[8, 4, -9]" :scale="[1, 1, 1]">
          <Suspense>
            <CanvasEye />
          </Suspense>
        </TresGroup>
        <!-- <TresGroup
          ref="textRef"
          name="TextSection"
          :position="[textPosition.x, textPosition.y, textPosition.z]"
          :scale="[textScale.x, textScale.y, textScale.z]"
        >
          <Suspense>
            <CanvasHeroText />
          </Suspense>
        </TresGroup> -->
        <TresGroup ref="planetsRef" name="PlanetsSection" :scale="[1, 1, 1]">
          <Suspense>
            <CanvasPlanets />
          </Suspense>
        </TresGroup>
        <TresGroup ref="footerRef" name="FooterSection">
          <Suspense>
            <CanvasFooter />
          </Suspense>
        </TresGroup>
      </TresGroup>
      <TresDirectionalLight ref="lightRef" :position="[2, 20, 20]" :intensity="3" />
      <Suspense>
        <CanvasEffect />
      </Suspense>
      <Suspense>
        <Environment files="/images/sky.hdr" :far="100" />
      </Suspense>
      <!--<Stats />-->
    </TresCanvas>
  </div>
</template>

<script setup lang="ts">
import { useProgress } from '@tresjs/cientos'
import { NoToneMapping } from 'three'
import { useAppStore } from '~/stores/app'
const { isDesktop } = useDevice()

const store = useAppStore()

const canvasRef = shallowRef()

defineProps({
  w: {
    type: Number,
    default: 500
  },
  h: {
    type: Number,
    default: 500
  }
})

const { hasFinishLoading, progress, items } = await useProgress()

const gl = {
  shadows: false,
  alpha: false,
  clearColor: 0x000000,
  toneMapping: NoToneMapping,
  powerPreference: 'high-performance',
  antialias: false,
  stencil: false,
  depth: false
}

watch(
  hasFinishLoading,
  () => {
    store.setLoading(false)
    // eslint-disable-next-line no-console
    console.log('items', items.value)
  },
  { once: true }
)
</script>
