import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as _91slug_939PkNmyhsPKMeta } from "/opt/build/repo/pages/bonus/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_93n4klry9NLLMeta } from "/opt/build/repo/pages/projects/[slug].vue?macro=true";
import { default as scrollKiSSp4FIC9Meta } from "/opt/build/repo/pages/scroll.vue?macro=true";
import { default as uiShFHYTmudWMeta } from "/opt/build/repo/pages/ui.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bonus-slug___en",
    path: "/bonus/:slug()",
    meta: _91slug_939PkNmyhsPKMeta || {},
    component: () => import("/opt/build/repo/pages/bonus/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___en",
    path: "/projects/:slug()",
    meta: _91slug_93n4klry9NLLMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "scroll___en",
    path: "/scroll",
    component: () => import("/opt/build/repo/pages/scroll.vue").then(m => m.default || m)
  },
  {
    name: "ui___en",
    path: "/ui",
    component: () => import("/opt/build/repo/pages/ui.vue").then(m => m.default || m)
  }
]