<script setup lang="ts">
import { TextureLoader, Points, PointsMaterial, BufferGeometry, Vector3 } from 'three'
import { useLoop } from '@tresjs/core'
import { useGLTF } from '@tresjs/cientos'
import gsap from 'gsap'
// import { Raycaster, Vector2 } from 'three'
import { MeshSurfaceSampler } from 'three/addons/math/MeshSurfaceSampler.js'
import { useAppStore } from '~/stores/app'
import mist from '~/assets/images/footer/mist.png'
import starTextureFile from '~/assets/images/icons/star-alpha.png'

const { width } = useWindowSize()
/*
const { camera } = useTresContext()
const raycaster = new Raycaster()
*/

const { isMobile, isDesktop } = useDevice()
const store = useAppStore()
const path = '/models/logo.glb'
const { scene } = await useGLTF(path, { draco: true })

const logoRef = shallowRef()

const textureLoader = new TextureLoader().load(starTextureFile)
const texture = ref()
texture.value = textureLoader

const textureMist = shallowRef()
textureMist.value = new TextureLoader().load(mist)

const footerPosition = ref({ x: 0, y: 0, z: 0 })
const footerScrollOffset = ref(0)

const gu = {
  time: { value: 4 },
  alpha: { value: 0 }
}

const m = new PointsMaterial({
  color: 0x12e2ff,
  size: 0.15,
  transparent: true,
  depthWrite: false,
  onBeforeCompile: (shader) => {
    shader.uniforms.time = gu.time
    shader.uniforms.alpha = gu.alpha
    shader.vertexShader = `
      uniform float time;
      varying float vSinVal;
      bool isEvenFloat(float num) {
        float decimalPart = fract(num);
        float nearestEven = floor(decimalPart * 0.5) * 2.0;
        if(decimalPart == nearestEven) {
            return true;
        } else {
            return false;
        }
      }
      ${shader.vertexShader}
    `
      .replace(
        '#include <begin_vertex>',
        `#include <begin_vertex>
        vec3 wPos = vec3(modelMatrix * vec4(position, 1.));
        vec3 p = position;
        vec3 pos = vec3(p.x, p.y, p.z);

       isEvenFloat(pos.y) ?  pos.x = pos.x*time:pos.z = pos.z*time;
       if(p.y>0.0) {
          pos.y = pos.y*time;
        } else {
          pos.z = pos.z*time;
        }

      `
      )
      .replace(
        'gl_PointSize = size;',
        `
      float t = time * 0.1;
      wPos.y -= t;
      float sinVal = sin(mod(wPos.y * 2., PI2)) * 0.4 + 0.5;
      vSinVal = sinVal;
      float sizeChange = 1. + sinVal * 3.;
      gl_PointSize = size * sizeChange;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0 );
      `
      )

    shader.fragmentShader = `
      uniform float time;
      uniform float alpha;
      varying float vSinVal;
      ${shader.fragmentShader}
    `
      .replace(
        '#include <clipping_planes_fragment>',
        `#include <clipping_planes_fragment>
        float dist = length(gl_PointCoord.xy - 0.9);
        if (dist > 0.1) discard;
      `
      )
      .replace(
        '#include <alphatest_fragment>',
        `#include <alphatest_fragment>
        diffuseColor.rgb = mix(diffuseColor.rgb, vec3(0.07, 0.88, 1), vSinVal);
        float f = smoothstep(0.5, 0.1, dist);
        diffuseColor.a = alpha;

      `
      )
  }
})

const o = scene.children[0]
const sampler = new MeshSurfaceSampler(o).build()
const sampledGeometry = new BufferGeometry().setFromPoints(
  new Array(isMobile ? 400 : 2000).fill().map((_) => {
    const p = new Vector3()
    sampler.sample(p)
    return p
  })
)

const p = new Points(sampledGeometry, m)

const { onBeforeRender } = useLoop()
const logoVisible = ref(false)

onBeforeRender(() => {
  footerPosition.value.y = store.footerScroll - footerScrollOffset.value
  const logoTreshold = width < 768 ? -2 : -4
  if (footerPosition.value.y >= logoTreshold && logoVisible.value === false) {
    logoVisible.value = true
    gsap.to(gu.time, { value: 1, duration: 1.5, ease: 'power2.inOut' })
    gsap.to(logoRef.value.rotation, { y: 0, duration: 1.5, ease: 'power2.inOut' })
    gsap.to(gu.alpha, { value: 1, duration: 1.5, ease: 'power2.inOut' })
  } else if (footerPosition.value.y <= logoTreshold && logoVisible.value === true) {
    logoVisible.value = false
    gsap.to(gu.time, { value: 4, duration: 1.5, ease: 'power2.inOut' })
    gsap.to(logoRef.value.rotation, { y: 1, duration: 1.5, ease: 'power2.inOut' })
    gsap.to(gu.alpha, { value: 0, duration: 1.5, ease: 'power2.inOut' })
  }
})

/*
function onMouseMove(e: MouseEvent) {
  if (width.value > 1024) {
    const coords = new Vector2()
    coords.x = getMousePos(e).x
    coords.y = getMousePos(e).y
    console.log(camera.value)
    raycaster.setFromCamera(coords, camera.value)
    const intersects = raycaster.intersectObjects([p])
    console.log(intersects)

    // displace the intersected objects
    for (let i = 0; i < intersects.length; i++) {
      const point = intersects[i]?.object.geometry.vertices[intersects[i].index]
      point.x += (Math.random() - 0.5) * 0.1
      point.y += (Math.random() - 0.5) * 0.1
      point.z += (Math.random() - 0.5) * 0.1
    }
    p.geometry.verticesNeedUpdate = true
  }
}

onMounted(() => {
  nextTick(() => {
    document.addEventListener('mousemove', onMouseMove)
  })
})

onUnmounted(() => {
  document.removeEventListener('mousemove', onMouseMove)
})
*/
</script>

<template>
  <TresGroup ref="footerRef" :position="[0, footerPosition.y, 0]" :scale="[1, 1, 1]">
    <Precipitation
      v-if="isMobile"
      :rotation="[0, 0, 1.7]"
      :position="[-4, -4, -2]"
      :randomness="0"
      :speed="0.05"
      :count="50"
      :area="[4, 30, 1]"
      :color="0xffffff"
      :opacity="0.6"
      :alphaMap="texture"
    />
    <Precipitation
      v-else-if="isDesktop"
      :rotation="[0, 0, 1.7]"
      :position="[-4, -4, -2]"
      :randomness="0"
      :speed="0.05"
      :count="200"
      :area="[4, 30, 1]"
      :color="0xffffff"
      :opacity="0.6"
      :alphaMap="texture"
    />

    <Levioso :speed="1">
      <primitive
        ref="logoRef"
        :object="p"
        :scale="[80, 80, 80]"
        :rotation="[1.4, 1, 0]"
        :position="[2.2, -1, 0]"
      />
    </Levioso>
    <Levioso :speed="4">
      <Plane :args="[30, 10]" :position="[2.2, 0, 3]" :rotation="[0.1, 0, 0]">
        <TresMeshBasicMaterial :map="textureMist" transparent :opacity="0.1" :depthWrite="false" />
        /></Plane
      >
    </Levioso>
    >
  </TresGroup>
</template>
