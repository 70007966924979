import revive_payload_client_grIdgpQ1gN from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_I1utZfvDgq from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hv8BnISasl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MF5ofYYH84 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XftlaTrDpp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2T4ikMz3xY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LAqCA9rnGb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_soheVgGrCu from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.20.0_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_aPd7g60yxQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CFCWXwuulQ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.20.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_334cCbw1oC from "/opt/build/repo/node_modules/.pnpm/nuxt-graphql-client@0.2.36_graphql-tag@2.12.6_rollup@4.20.0_typescript@5.5.4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_Lqax9MW2xR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_rollup@4.20.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_YxJHrUetd3 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_rollup@4.20.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunks_error_handler_client_41WOvKeXQW from "/opt/build/repo/plugins/chunks-error-handler.client.ts";
import gql_error_zbgPDv3vAX from "/opt/build/repo/plugins/gql-error.ts";
import gtag_client_Zw8EQXNVTz from "/opt/build/repo/plugins/gtag.client.ts";
import vue_gtm_client_stBjLl0OeM from "/opt/build/repo/plugins/vue-gtm.client.ts";
import vue_uid_zuF101RSew from "/opt/build/repo/plugins/vue-uid.ts";
export default [
  revive_payload_client_grIdgpQ1gN,
  unhead_I1utZfvDgq,
  router_hv8BnISasl,
  payload_client_MF5ofYYH84,
  navigation_repaint_client_XftlaTrDpp,
  check_outdated_build_client_2T4ikMz3xY,
  chunk_reload_client_LAqCA9rnGb,
  plugin_vue3_soheVgGrCu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_aPd7g60yxQ,
  plugin_CFCWXwuulQ,
  plugin_334cCbw1oC,
  switch_locale_path_ssr_Lqax9MW2xR,
  i18n_YxJHrUetd3,
  chunks_error_handler_client_41WOvKeXQW,
  gql_error_zbgPDv3vAX,
  gtag_client_Zw8EQXNVTz,
  vue_gtm_client_stBjLl0OeM,
  vue_uid_zuF101RSew
]