export const getObjKey = (obj: { [key: string]: any }, value: any) =>
  Object.keys(obj).find((key) => obj[key] === value)
export const isObjEmpty = (obj: { [key: string]: any }) => Object.keys(obj).length === 0
export const round = (num: number) =>
  num
    .toFixed(7)
    .replace(/(\.[0-9]+?)0+$/, '$1')
    .replace(/\.0$/, '')
export const rem = (px: number) => `${round(px / 16)}rem`
/**
 * Accepts pixel values for both arguments.
 */
export const unitLess = (lineHeight: number, fontSize: number) =>
  `${lineHeight / fontSize}/* ${lineHeight}px */`

export const getMousePos = (e: MouseEvent) => {
  return {
    x: e.clientX,
    y: e.clientY
  }
}
