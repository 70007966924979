<template>
  <video ref="videoRef" class="video-js" playsinline></video>
  <VideoPlayerControls
    :controls="isControlsVisible"
    :playing="isPlaying"
    :muted="isMuted"
    @play="togglePlay"
    @mute="toggleMuted"
  />
</template>

<script setup lang="ts">
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'
import 'video.js/dist/video-js.css'

export interface VideoPlayerProps {
  options?: Object
}

const props = defineProps<VideoPlayerProps>()
const emit = defineEmits([
  'ready',
  'play',
  'pause',
  'ended',
  'fullscreenchange',
  'useractive',
  'userinactive'
])

const videoRef = ref<HTMLVideoElement | null>(null)
const player = ref<Player | null>(null)
defineExpose({
  player
})

const isControlsVisible = ref(true)
const isPlaying = ref(false)
const isMuted = ref(false)

const togglePlay = () => {
  if (player.value) {
    if (player.value.paused()) {
      player.value.play()
    } else {
      player.value.pause()
    }
  }
}

const toggleMuted = () => {
  if (player.value) {
    if (player.value.muted()) {
      player.value.muted(false)
      isMuted.value = false
    } else {
      player.value.muted(true)
      isMuted.value = true
    }
  }
}

onMounted(() => {
  if (videoRef.value) {
    player.value = videojs(videoRef.value, props.options, () => {
      player?.value!.log('onPlayerReady', player.value)
    })

    player.value.on('play', () => {
      emit('play')
      isPlaying.value = true
    })

    player.value.on('pause', () => {
      emit('pause')
      isPlaying.value = false
    })

    player.value.on('ended', () => {
      emit('ended')
    })

    player.value.on('useractive', () => {
      emit('useractive')
      isControlsVisible.value = true
    })

    player.value.on('userinactive', () => {
      emit('userinactive')
      if (!isPlaying.value) {
        return
      }
      isControlsVisible.value = false
    })

    player.value.on('fullscreenchange', () => {
      emit('fullscreenchange')
    })
  }
})

onBeforeUnmount(() => {
  if (player.value) {
    player?.value!.dispose()
  }
})
</script>

<style lang="postcss">
.video-js {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .vjs-tech {
    width: 100%;
    height: auto;
    position: static;
    object-fit: cover;

    @screen lg {
      height: 100%;
      position: absolute;
    }
  }

  .vjs-control-bar {
    background-color: transparent;
  }

  .vjs-control-bar {
    bottom: 7vh;

    @screen lg {
      bottom: 9vh;
    }
  }

  .vjs-progress-control .vjs-progress-holder {
    height: 0.2em;
    margin: 0 5vw;

    @screen lg {
      margin: 0 8vw;
    }
  }
}
</style>
