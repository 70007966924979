<template>
  <TresGroup ref="planetsRef" name="Planets" :position="[0, planetsPosition.y, 0]">
    <Precipitation
      :rotation="[0, 0, 1.7]"
      :position="[-4, 0, 3]"
      :randomness="0"
      :speed="0.05"
      :count="45"
      :area="[5, 30, 1]"
      :color="0x12e2ff"
      :opacity="0.6"
      :alphaMap="texture"
    />
    <Precipitation
      :rotation="[0, 0, 1.7]"
      :position="[-4, 0, 2]"
      :randomness="0"
      :speed="0.05"
      :count="60"
      :area="[5, 30, 1]"
      :color="0xdc3d43"
      :opacity="0.6"
      :alphaMap="texture"
    />
    <Precipitation
      :rotation="[0, 0, 1.7]"
      :position="[-4, 1, -2]"
      :randomness="0"
      :speed="0.05"
      :count="200"
      :area="[3, 30, 1]"
      :color="0xffffff"
      :opacity="0.6"
      :alphaMap="texture"
    />
    <Levioso :speed="2">
      <Sphere
        ref="sphere1Ref"
        name="Sphere"
        :rotation="[0, 0, 0]"
        :position="[-3.7, 0, 0]"
        :scale="[0.4, 0.4, 0.4]"
      >
        <TresMeshPhongMaterial :color="0x285575" :shininess="4" :specular="0xffbc0e" />
        <Ring :args="[3.6, 4.4, 64]" :position="[0, 0, 0.2]" :rotation="[-1.3, 0.28, 0]">
          <TresMeshPhongMaterial :color="0x285575" :shininess="5" :specular="0xffffff" />
        </Ring>
        <Ring :args="[2.5, 3, 64]" :position="[0, 0, 0.2]" :rotation="[-1.3, 0.28, 0]">
          <TresMeshPhongMaterial :color="0x285575" :shininess="5" :specular="0xffffff" />
        </Ring>
      </Sphere>
    </Levioso>
    <Levioso :speed="2">
      <Sphere
        ref="sphere2Ref"
        name="Sphere"
        :rotation="[0, 0, 0]"
        :position="[0, 0, 0]"
        :scale="[0.8, 0.8, 0.8]"
      >
        <TresMeshPhongMaterial
          :color="0xae6c32"
          :map="planetTexture"
          :shininess="10"
          :specular="0xe4ae3e"
        />
      </Sphere>
    </Levioso>
    <Levioso :speed="2">
      <Sphere
        ref="sphere3Ref"
        name="Sphere"
        :rotation="[0, 0, 0]"
        :position="[3.7, 0.5, 0]"
        :scale="[0.8, 0.8, 0.8]"
      >
        <TresMeshPhongMaterial :color="0x434c63" :shininess="4" :specular="0xff0000" />

        <Ring :args="[2.2, 2.8, 64]" :position="[0, 0, 0.2]" :rotation="[-1.2, -0.5, 0]">
          <TresMeshPhongMaterial
            :color="0x285575"
            :shininess="5"
            :specular="0xffffff"
            transparent="true"
            opacity="0.5"
          />
        </Ring>
      </Sphere>
    </Levioso>
    <Levioso :speed="4">
      <Plane :args="[30, 10]" :position="[2.2, 0, 4]" :rotation="[0.1, 0, 0]">
        <TresMeshBasicMaterial :map="textureMist" transparent :opacity="0.1" :depthWrite="false" />
        /></Plane
      >
    </Levioso>
  </TresGroup>
</template>

<script setup lang="ts">
import { TextureLoader } from 'three'
import planetTextureFile from '~/assets/images/planets/planet-texture.jpg'
import starTextureFile from '~/assets/images/icons/star-alpha.png'
import mist from '~/assets/images/footer/mist.png'
import { useAppStore } from '~/stores/app'

const { isMobile } = useDevice()

const store = useAppStore()

const textureLoader = new TextureLoader().load(starTextureFile)
const texture = ref()
texture.value = textureLoader

const planetTextureLoader = new TextureLoader().load(planetTextureFile)
const planetTexture = ref()
planetTexture.value = planetTextureLoader

const textureMist = ref()
textureMist.value = new TextureLoader().load(mist)

const planetsScrollOffset = isMobile ? ref(-1.5) : ref(1.5)
const planetsPosition = ref({ x: 0, y: 0, z: 0 })

const { onBeforeRender } = useLoop()

onBeforeRender(() => {
  planetsPosition.value.y = store.planetsScroll - planetsScrollOffset.value
})
</script>
