import mitt from 'mitt'
import { RouteLocationNormalized } from 'vue-router'

interface RouteLocation {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
}

type ApplicationEvents = {
  'cursor:visibility': boolean
  'cursor:scale': boolean
  'page:transition-start': RouteLocation
  'page:transition-end': RouteLocation
}

const emitter = mitt<ApplicationEvents>()

export const useEvent = emitter.emit
export const useListen = emitter.on
export const useUnlisten = emitter.off
