import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["Site","Articles","Article","Bonus","Button","CallToAction","Image","Table","VideoEmbed","SectionHero","SectionTitle","Index","SectionCta","SectionBody","SectionArticles","SectionImageGallery","SectionContactForm","SectionInquiryForm","SectionFaq","SectionFeatures","SectionLogos","SectionMedia","SectionRelatedArticles","SectionTestimonials","SectionVideo","SectionProjectsList","Page","Project"]}
export const GqlArticle = (...params) => useGql()('Article', ...params)
export const GqlArticles = (...params) => useGql()('Articles', ...params)
export const GqlBonus = (...params) => useGql()('Bonus', ...params)
export const GqlIndex = (...params) => useGql()('Index', ...params)
export const GqlPage = (...params) => useGql()('Page', ...params)
export const GqlProject = (...params) => useGql()('Project', ...params)
export const GqlSite = (...params) => useGql()('Site', ...params)