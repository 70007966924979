<template>
  <TresGroup ref="eyeRef" name="Eye" :scale="[1.9, 1.9, 1.9]" :position="[0, eyePosition.y, 0]">
    <Levioso :speed="2">
      <Sphere
        name="Sphere"
        :args="[1.6, 32, 32]"
        :rotation="[0, 0, 0]"
        :position="[-0.5, -0.5, -0.4]"
      >
        <MeshGlassMaterial
          :reflectivity="0.9"
          :ior="2"
          :dispersion="1.2"
          :thickness="0.7"
          :transmission="0.99"
          :specularIntensity="2"
          :specularColor="0xeba862"
        />
        <!--
        <Circle
          :args="[1, 64, 128]"
          :rotation="[-0.6, 0.2, zEyeRotate]"
          :position="[-0.05, 0.15, 0.6]"
        >
          <TresMeshPhongMaterial :map="textureEye" />
        </Circle>
        <Circle
          :args="[0.3, 24, 64]"
          :rotation="[-0.6, 0.2, zEyeRotate]"
          :position="[-0.05, 0.16, 0.62]"
        >
          <TresMeshBasicMaterial :color="0x000000" />
        </Circle>-->
        <GLTFModel
          :position="[0, 0, 0]"
          :scale="[1.5, 1.5, 1.5]"
          :rotation="[-0.4, 0, zEyeRotate]"
          path="/models/eyeball.glb"
          :draco="true"
        />
      </Sphere>
    </Levioso>
    <Levioso :speed="2">
      <Ring
        ref="cylinderRef"
        :args="[2.0, 5.2, 80]"
        :rotation="[-0.7, 0.25, 0]"
        @pointer-enter="onPointerEnter"
        @pointer-leave="onPointerLeave"
        @click="showModal"
      >
        <TresMeshStandardMaterial :map="texture" />
        <Sphere :args="[0.2, 24, 24]" :position="[xPosition1, yPosition1, 0.5]">
          <TresMeshBasicMaterial :color="0xdc3d43" />
        </Sphere>
        <Sphere :args="[0.1, 12, 12]" :position="[xPosition2, yPosition2, 0.5]">
          <TresMeshBasicMaterial :color="0xdc3d43" />
        </Sphere>
      </Ring>
      <Ring
        ref="ring1Ref"
        :args="[3.25, 3.65, 128]"
        :position="[0, 0, 0.2]"
        :rotation="[-0.7, 0.25, 0]"
      >
        <MeshGlassMaterial
          :reflectivity="4"
          :ior="3"
          :dispersion="0.5"
          :thickness="0.4"
          :transmission="0.99"
        />
      </Ring>
      <Ring
        ref="ring2Ref"
        :args="[2.7, 3.1, 128]"
        :position="[0, 0, 0.2]"
        :rotation="[-0.7, 0.25, 0]"
      >
        <MeshGlassMaterial
          :reflectivity="4"
          :ior="3"
          :dispersion="0.5"
          :thickness="0.4"
          :transmission="0.99"
        />
      </Ring>
      <Ring
        ref="ring3Ref"
        :args="[5.8, 6, 128]"
        :position="[0, 0, -0.5]"
        :rotation="[-0.7, 0.25, 0]"
      >
        <TresMeshBasicMaterial :color="0x146e78" :shininess="5" :specular="0x285575" />
      </Ring>
      <Ring
        ref="ring4Ref"
        :args="[5.1, 5.3, 86]"
        :position="[0, 0, -0.2]"
        :rotation="[-0.7, 0.25, 0]"
        :scale="[cylinderScale, cylinderScale, cylinderScale]"
      >
        <TresMeshPhongMaterial
          :color="0x146e78"
          :emissive="0x146e78"
          :emissiveIntensity="cylinderEmissive"
        />
      </Ring>
    </Levioso>
    <Levioso :speed="1">
      <Plane :args="[30, 10]" :position="[2.2, -3, 3]" :rotation="[0.1, 0, 0]">
        <TresMeshBasicMaterial :map="textureMist" transparent :opacity="0.1" :depthWrite="false" />
        /></Plane
      >
    </Levioso>
  </TresGroup>
</template>

<script setup lang="ts">
import { useVideoTexture, Levioso, MeshGlassMaterial, GLTFModel } from '@tresjs/cientos'
import { TextureLoader, LinearFilter, SRGBColorSpace } from 'three'
import { gsap } from 'gsap'
import eyeImage from '~/assets/images/backgrounds/eye-texture.png'
import { useAppStore } from '~/stores/app'
import mist from '~/assets/images/hero/mist.png'

const { showVideo } = useModal()
const { width } = useWindowSize()
const store = useAppStore()
const { isMobile } = useDevice()

const texture = ref()
texture.value = await useVideoTexture('/videos/hero_loop.mp4')
texture.value.anisotropy = 16
// texture.value.encoding = sRGBEncoding
texture.value.magFilter = LinearFilter
texture.value.colorSpace = SRGBColorSpace

const textureMist = shallowRef()
textureMist.value = new TextureLoader().load(mist)

const textureEye = shallowRef()
textureEye.value = new TextureLoader().load(eyeImage)

const eyePosition = ref({ x: 0, y: 0, z: 0 })

const eyeScrollOffset = isMobile ? ref(-2) : ref(3)

const cylinderScale = ref(1)
const cylinderEmissive = ref(20)

const eyeRef = shallowRef<any | null>(null)
const xPosition1 = shallowRef(4)
const yPosition1 = shallowRef(-4)
const xPosition2 = shallowRef(0)
const yPosition2 = shallowRef(-2)
const zEyeRotate = shallowRef(0)

const { onBeforeRender } = useLoop()

onBeforeRender(() => {
  const date1 = Date.now() * 0.0005
  const date2 = Date.now() * 0.0025
  xPosition1.value = Math.cos(date1) * 3
  yPosition1.value = Math.sin(date1) * 3
  xPosition2.value = Math.cos(date2) * 4
  yPosition2.value = Math.sin(date2) * 4
  zEyeRotate.value = -date2 * 0.09
  eyePosition.value.y = store.eyeScroll - eyeScrollOffset.value
})

function onMouseMove(e: MouseEvent) {
  if (width.value > 1024) {
    const coords = getMousePos(e)
    if (eyeRef.value) {
      eyeRef.value.rotation.y = (coords.x - window.innerWidth / 2) / 4000
      eyeRef.value.rotation.x = (coords.y - window.innerHeight / 2) / 4000
    }
  }
}

const isPointerOver = ref(false)
const showModal = () => {
  if (width.value < 1024) {
    return
  }
  useEvent('cursor:scale', false)
  showVideo()
}

const onPointerEnter = () => {
  if (isPointerOver.value || width.value < 1024) {
    return
  }
  useEvent('cursor:scale', true)
  isPointerOver.value = true
  gsap.to(cylinderScale, {
    value: 1.02,
    duration: 0.5,
    repeat: 0,
    delay: 0,
    ease: 'power2.inOut'
  })
  gsap.to(cylinderEmissive, {
    value: 23,
    duration: 0.5,
    repeat: 0,
    delay: 0,
    ease: 'power2.inOut'
  })
}

const onPointerLeave = () => {
  if (!isPointerOver.value || width.value < 1024) {
    return
  }
  useEvent('cursor:scale', false)
  isPointerOver.value = false
  gsap.to(cylinderScale, {
    value: 1,
    duration: 0.5,
    repeat: 0,
    delay: 0,
    ease: 'power2.inOut'
  })
  gsap.to(cylinderEmissive, {
    value: 20,
    duration: 0.5,
    repeat: 0,
    delay: 0,
    ease: 'power2.inOut'
  })
}

onMounted(() => {
  nextTick(() => {
    document.addEventListener('mousemove', onMouseMove)
  })
})

onUnmounted(() => {
  document.removeEventListener('mousemove', onMouseMove)
})
</script>
