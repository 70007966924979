import { defineNuxtPlugin } from '#app/nuxt'
import { LazySectionArticles, LazySectionRelatedArticles, LazySectionFaq, LazySectionContactForm, LazySectionInquiryForm, LazySectionImageGallery, LazySectionLogos, LazySectionMedia, LazySectionVideo, LazySectionProjects, LazyThreeCube, LazySectionBody, LazySectionCta, LazySectionHero, LazySectionTitle, LazySectionFeatures, LazySectionTestimonials } from '#components'
const lazyGlobalComponents = [
  ["SectionArticles", LazySectionArticles],
["SectionRelatedArticles", LazySectionRelatedArticles],
["SectionFaq", LazySectionFaq],
["SectionContactForm", LazySectionContactForm],
["SectionInquiryForm", LazySectionInquiryForm],
["SectionImageGallery", LazySectionImageGallery],
["SectionLogos", LazySectionLogos],
["SectionMedia", LazySectionMedia],
["SectionVideo", LazySectionVideo],
["SectionProjects", LazySectionProjects],
["ThreeCube", LazyThreeCube],
["SectionBody", LazySectionBody],
["SectionCta", LazySectionCta],
["SectionHero", LazySectionHero],
["SectionTitle", LazySectionTitle],
["SectionFeatures", LazySectionFeatures],
["SectionTestimonials", LazySectionTestimonials],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
