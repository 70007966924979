import { defineStore } from 'pinia'
import { useBlogStore } from '~/stores/BlogStore'
import { Site, MenuItemRecord, MenuCtaRecord, FooterLinkRecord } from '~/types/datocms'

export interface AppState {
  _site: Site
  allMenuCtas: MenuCtaRecord[]
  allMenuItems: MenuItemRecord[]
  allFooterLinks: FooterLinkRecord[]
  isLoading: boolean
  isPendingLocaleChange: boolean
  layout: 'default' | 'index' | 'inquiry'
  lenis: any
  isCustomCursor: boolean
  isHeaderVisible: boolean
  isEyeVisible: boolean
  eyeScroll: 0
  planetsScroll: 0
  footerScroll: 0
  heroSection: HTMLElement | null
  packagesSection: HTMLElement | null
  contactSection: HTMLElement | null
}

export const useAppStore = defineStore('AppStore', {
  state: (): AppState => {
    return {
      _site: {} as Site,
      allMenuCtas: [],
      allMenuItems: [],
      allFooterLinks: [],
      isLoading: true,
      isPendingLocaleChange: false,
      layout: 'default',
      lenis: null,
      isCustomCursor: false,
      isHeaderVisible: false,
      isEyeVisible: false,
      eyeScroll: 0,
      planetsScroll: 0,
      footerScroll: 0,
      heroSection: null,
      packagesSection: null,
      contactSection: null
    }
  },
  actions: {
    async fetchData() {
      const blogStore = useBlogStore()

      const { data } = await useAsyncGql('Site', {
        options: {
          initialCache: false
        }
      })

      this._site = data?.value._site as Site
      this.allMenuCtas = data?.value.allMenuCtas as MenuCtaRecord[]
      this.allMenuItems = data?.value.allMenuItems as MenuItemRecord[]
      this.allFooterLinks = data?.value.allFooterLinks as FooterLinkRecord[]
      blogStore.pushCategories(data?.value?.allCategories)
    },
    async updateData() {
      const blogStore = useBlogStore()

      this.setLoading(true)

      const data = await GqlSite()

      this._site = data._site as Site
      this.allMenuCtas = data.allMenuCtas as MenuCtaRecord[]
      this.allMenuItems = data.allMenuItems as MenuItemRecord[]
      this.allFooterLinks = data.allFooterLinks as FooterLinkRecord[]
      this.setLoading(false)
      blogStore.pushCategories(data?.allCategories)
    },
    setLoading(loading: boolean) {
      useTimeoutFn(() => {
        this.isLoading = loading
      }, 0)
    },
    setPendingLocaleChange(pending: boolean) {
      this.isPendingLocaleChange = pending
    },
    setLayout(layout: AppState['layout']) {
      this.layout = layout
    },
    setCustomCursor(bool: boolean) {
      this.isCustomCursor = bool
    },
    setHeaderVisibility(bool: boolean) {
      this.isHeaderVisible = bool
    },
    setEyeVisibility(bool: boolean) {
      this.isEyeVisible = bool
    }
  }
})
