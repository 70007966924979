<template>
  <div>
    <CanvasStage
      ref="stageRef"
      class="fixed left-0 top-0 -z-1 h-[100vh] w-full lg:z-0"
      :w="width"
      :h="height"
    />

    <!--
    <CanvasScroll
      ref="stageRef"
      class="fixed left-0 top-0 h-[100vh] w-full"
      :w="width"
      :h="height"
    />

    <CanvasCameraScroll
      ref="stageRef"
      class="fixed left-0 top-0 h-[100vh] w-full"
      :w="width"
      :h="height"
    />
    -->
    <NuxtLayout
      class="relative transition-opacity lg:pointer-events-none"
      :class="store.isLoading ? 'opacity-0' : 'opacity-100'"
    >
      <NuxtPage
        :transition="{
          name: 'page'
        }"
      />
    </NuxtLayout>
    <ClientOnly>
      <LazyModalOverlay
        v-if="!store.isLoading"
        :show="isVideoVisible"
        :showClose="!isUserInactive"
        :showBack="false"
        @onClose="hideVideo"
      >
        <VideoPlayer
          ref="playerRef"
          class="w-full"
          :options="{
            controls: true,
            autoplay: true,
            preload: 'metadata',
            sources: [
              {
                src: video,
                type: 'video/mp4'
              }
            ],
            fill: true,
            bigPlayButton: false,
            controlBar: {
              volumePanel: false,
              playToggle: false,
              fullscreenToggle: false,
              pictureInPictureToggle: false,
              currentTimeDisplay: false,
              remainingTimeDisplay: false
            }
          }"
          @userinactive="onUserInactive"
          @useractive="onUserActive"
        />
      </LazyModalOverlay>
    </ClientOnly>
    <LazyCookieBanner v-if="loadCookieBanner" />
    <ClientOnly>
      <MouseCursor v-if="isPointer" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import Player from 'video.js/dist/types/player'
import video from '~/assets/videos/hero.mp4'
import { useAppStore } from '~/stores/app'

const appConfig = useAppConfig()
const store = useAppStore()
// const { isDesktop } = useDevice()

store.fetchData()
/*
const { finalizePendingLocaleChange } = useI18n()

const onBeforeEnter = async () => {
  if (store.isPendingLocaleChange) {
    store.setLoading(true)
    await finalizePendingLocaleChange()
    await store.fetchData()
    store.setPendingLocaleChange(false)
  }
}
  */

const stageRef = ref()
const { width, height } = useElementSize(stageRef)

const loadCookieBanner = ref(false)

const { isVideoVisible, hideVideo } = useModal()
const isPointer = ref(false)
const isUserInactive = ref(false)
const playerRef = ref<Player | null>(null)

const onUserInactive = () => {
  if (playerRef.value?.player.paused()) {
    return
  }
  isUserInactive.value = true
  useEvent('cursor:visibility', false)
}

const onUserActive = () => {
  isUserInactive.value = false
  useEvent('cursor:visibility', true)
}

onMounted(() => {
  if (window) {
    isPointer.value = window.matchMedia('(pointer:fine)').matches
  }

  useTimeoutFn(() => {
    loadCookieBanner.value = true
  }, 3000)
})

useHead({
  titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk}` : appConfig.title)
})
</script>
