import { defineStore } from 'pinia'

export const useBlogStore = defineStore('BlogStore', {
  state: () => ({
    allArticles: [],
    allArticlesCount: 0,
    allCategories: [],
    isLoading: false,
    itemsPerPage: 1,
    loadMore: true,
    skipItems: 0,
    isEmpty: false,
    allFilters: [],
    allFiltersCount: 0
  }),
  getters: {
    getCategoryByName(state) {
      return (catName: string) => state.allCategories.find((cat) => cat.name === catName)
    }
  },
  actions: {
    async fetchArticles(locale: string, filter?: any) {
      this.setLoading(true)

      const { data } = await useAsyncGql('Articles', {
        // @ts-ignore
        locale,
        first: this.itemsPerPage,
        skip: this.skipItems,
        eq: filter,
        options: {
          initialCache: false
        }
      })

      this.allArticlesCount = data?.value!._allArticlesMeta.count

      if (data?.value!._allArticlesMeta.count === 0) {
        this.setEmpty(true)
      } else {
        this.setEmpty(false)
      }

      const results = data?.value!.allArticles
      if (results.length > 0) {
        this.allArticles.push(...results)
        this.skipItems += results.length
      }

      this.setLoadMore()

      this.setLoading(false)
    },
    pushCategories(payload: any) {
      this.allCategories = payload
    },
    pushFilters(query: string) {
      this.allFilters = query
      this.allFiltersCount = 1
    },
    setItemsPerPage(amount: number) {
      this.itemsPerPage = amount
    },
    setLoading(bool: boolean) {
      this.isLoading = bool
    },
    setLoadMore() {
      this.skipItems < this.allArticlesCount ? (this.loadMore = true) : (this.loadMore = false)
    },
    setEmpty(bool: boolean) {
      this.isEmpty = bool
    },
    resetArticles() {
      this.allArticles = []
      this.allArticlesCount = 0
      this.skipItems = 0
    }
  }
})
