<template>
  <div class="container mx-auto">
    <Logo />
    <div class="flex h-screen flex-col justify-center">
      <div class="mx-auto w-full max-w-4xl">
        <div class="flex">
          <div class="text-3xl font-bold">{{ error?.statusCode }}</div>
          <h1 class="text-lg self-center pl-2">{{ error?.message }}</h1>
        </div>
        <div class="font-sm mt-5">
          <Button class="cursor-pointer underline" @click="resetError">{{
            $t('app.safetyLink.label')
          }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  error: Object
})

const { t } = useI18n()
const localePath = useLocalePath()

// Error handling
const resetError = async (error: { value: null }) => {
  await navigateTo(localePath(t('app.safetyLink.to')))
  error.value = null
}
</script>
