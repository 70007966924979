import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useAppStore } from '~/stores/app'

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useAppStore()

  const hasStartViewTransition = () => typeof document.startViewTransition !== 'undefined'

  if (import.meta.client) {
    const scrollPosition = window.scrollY
    sessionStorage.setItem('previousScrollPosition', scrollPosition.toString())
  }

  // Fallback for browsers without startViewTransition support
  if (import.meta.server || !hasStartViewTransition()) {
    if (to.meta.pageTransition) {
      to.meta.pageTransition.onBeforeEnter = () => {
        store.lenis.scrollTo(0, {
          immediate: true
        })
        ScrollTrigger.refresh()
      }

      to.meta.pageTransition.onEnter = () => {
        useTimeoutFn(() => {
          useEvent('page:transition-end', { to, from })
          useAlignScrollableElements()
        }, 500)
      }
    }

    if (from.meta.pageTransition) {
      from.meta.pageTransition.onBeforeLeave = () => {
        useEvent('page:transition-start', { to, from })
      }
    }
    return
  }

  // Original logic for browsers with startViewTransition support
  if (to.meta.pageTransition) {
    to.meta.pageTransition.onBeforeEnter = () => {
      store.lenis.scrollTo(0, {
        immediate: true
      })
      useTimeoutFn(() => {
        ScrollTrigger.refresh()
      }, 0) // slight delay
    }

    to.meta.pageTransition.onEnter = () => {
      useTimeoutFn(() => {
        useEvent('page:transition-end', { to, from })
        useAlignScrollableElements()
      }, 500)
    }
  }

  if (from.meta.pageTransition) {
    from.meta.pageTransition.onBeforeLeave = () => {
      useEvent('page:transition-start', { to, from })
    }
  }
})
