<script lang="ts" setup>
import { useTresContext } from '@tresjs/core'

const { renderer } = useTresContext()

const { width } = useWindowSize

if (width <= 768) {
  renderer.value.setPixelRatio(Math.min(window.devicePixelRatio, 0.7))
} else {
  renderer.value.setPixelRatio(Math.min(window.devicePixelRatio, 1.5))
}
</script>
