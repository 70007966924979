<template>
  <TresPerspectiveCamera
    ref="cameraRef"
    :rotation="[cameraRotation.x, cameraRotation.y, cameraRotation.z]"
    :position="[cameraPosition.x, cameraPosition.y, cameraPosition.z]"
    :args="[45, width / height, 1, 1000]"
  />
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { useAppStore } from '~/stores/app'

const { width, height } = useWindowSize()
const cameraPosition = ref({ x: 0, y: 0, z: 10 })
const cameraRotation = ref({ x: 0, y: 0, z: 0 })
const cameraRef = ref()
const route = useRoute()
const store = useAppStore()

if (width.value < 400) {
  cameraPosition.value.z = 25
} else if (width.value < 1024) {
  cameraPosition.value.z = 15
} else if (width.value < 1920) {
  cameraPosition.value.z = 10
} else if (width.value < 3000) {
  cameraPosition.value.z = 15
} else {
  cameraPosition.value.z = 25
}

if (route.path === '/') {
  // Set initial top offset to do the transition effect from top
  cameraPosition.value.y = -15
} else if (route.path !== '/') {
  // Position for all non-home pages
  cameraRotation.value.y = 2.2
}

onMounted(() => {
  if (cameraPosition.value) {
    store.camera = cameraRef.value
    if (route.path === '/') {
      // Do the transition
      useTimeoutFn(() => {
        gsap.to(cameraPosition.value, {
          y: 0,
          duration: 3,
          repeat: 0,
          delay: 0,
          ease: 'power2.inOut'
        })
      }, 200)
    }

    useListen('page:transition-start', (route) => {
      if (route.from.path === '/') {
        useTimeoutFn(() => {
          /* gsap.to(cameraPosition.value, {
            x: -40,
            duration: 2.5,
            repeat: 0,
            delay: 0,
            ease: 'power2.inOut'
          }) */
          gsap.to(cameraRotation.value, {
            y: 2.2,
            duration: 2.5,
            repeat: 0,
            delay: 0,
            ease: 'power2.inOut'
          })
        }, 50)
      }
      if (route.to.path === '/') {
        useTimeoutFn(() => {
          /* gsap.to(cameraPosition.value, {
            x: 0,
            duration: 2.5,
            repeat: 0,
            delay: 0,
            ease: 'power2.inOut'
          }) */
          gsap.to(cameraRotation.value, {
            y: 0,
            duration: 2.5,
            repeat: 0,
            delay: 0,
            ease: 'power2.inOut'
          })
        }, 50)
      }
    })
    /* useListen('page:transition-end', (route) => {}) */
  }
})
</script>
