<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :to="to ? to : undefined"
    :class="variant !== 'unstyled' && classList"
    @click="onClick"
  >
    <span>
      {{ label }}

      <slot v-if="!label" />
    </span>
    <Icon
      v-if="icon"
      :name="typeof icon === 'string' ? icon : icon.name"
      :size="typeof icon === 'string' ? 'sm' : icon.size"
      :color="typeof icon === 'string' ? 'white group-hover:black ' : icon.color"
    />
  </component>
</template>

<script setup lang="ts">
import type { IconSize, IconName } from '~/components/media/Icon.vue'
const NuxtLink = resolveComponent('NuxtLink')

type ButtonVariant = 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled'

interface Props {
  disabled?: boolean
  icon?: {
    color?: string
    name: IconName
    size?: IconSize
  }
  variant?: ButtonVariant
  label?: string | null
  loading?: boolean
  to?: string | object | undefined | null
  prevent?: boolean
  size?: 'sm' | 'md' | 'lg'
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: 'solid',
  loading: false,
  prevent: false,
  size: 'md'
})

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

const $style = useCssModule()

const classList = computed(() => {
  return [
    // Element
    $style.button,
    {
      // Style
      [$style.solid]: props.variant === 'solid',
      [$style.outline]: props.variant === 'outline',
      'button-gradient': props.variant === 'outline',
      [$style.ghost]: props.variant === 'ghost',
      [$style.link]: props.variant === 'link',

      // Size
      [$style.sm]: props.size === 'sm',
      [$style.md]: props.size === 'md',
      [$style.lg]: props.size === 'lg',

      // State
      [$style.disabled]: props.disabled || props.loading
    }
  ]
})

function onClick(event: MouseEvent) {
  if (props.disabled || props.loading || props.prevent) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}
</script>

<style lang="postcss" module>
.button {
  --color-primary: theme('colors.primary');
  -webkit-tap-highlight-color: transparent;

  @apply relative inline-flex h-[var(--size)] cursor-pointer items-center justify-center whitespace-nowrap rounded-full leading-none no-underline transition-colors;

  span {
    @apply inline-flex h-full w-full items-center justify-center rounded-full bg-primary bg-opacity-10;
  }
}

/* Solid */
.solid {
  @apply bg-primary text-black;
  box-shadow: 0px 3.3px 36.3px #12e2ff;
}

/* Outline */

.outline {
  @apply relative border border-primary bg-transparent text-primary;

  &::before {
    @apply absolute inset-0 rounded-full bg-transparent opacity-0 transition-opacity duration-500;
    content: '';
    box-shadow: 0px 3.3px 36.3px #12e2ff;
  }

  &:hover::before {
    @apply opacity-100;
  }
}

/* Ghost 👻 */

.ghost {
  @apply bg-primary bg-opacity-0 text-primary;

  &:hover {
    @apply bg-opacity-20;
  }
}

/* Size */

.sm {
  --size: 30px;
  @apply text-xs;

  span {
    @apply px-8;
  }
}

.md {
  --size: 36px;
  @apply text-sm font-bold;

  span {
    @apply px-6;
  }
}

.lg {
  --size: 40px;
  @apply text-body font-bold;

  span {
    @apply px-14;
  }
}

/* State */
.disabled {
  @apply pointer-events-none cursor-default opacity-50;
}
</style>
